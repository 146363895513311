.episodes-container {
  background-image: url('../../../public/starfield.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.episode-section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.section-title {
  font-size: 3rem;
  color: #fff200;
  margin-bottom: 20px;
}

.episode-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Ensures cards are spaced out evenly */
  width: 100%; /* Ensures the container takes full width */
}

.episode-card {
  flex: 1 0 30%; /* Shrink or grow as needed, basis 30% */
  margin: 10px; /* Space around each card */
}

.btn-primary {
  margin-left: auto;
  padding-right: 20px;
  background-color: #ed1c24;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 24px;
  font-weight: bold;

  &:hover {
    background-color: darken(#ed1c24, 10%);
  }
}

@media (max-width: 768px) {
  .episode-card {
    flex-basis: 100%; /* Each card takes full width of the container on small screens */
    margin: 10px 0; /* Adjust vertical margin, remove horizontal */
  }

  .episode-cards {
    justify-content: center; /* Centers cards on smaller screens */
  }

  .section-title {
    font-size: 24px; /* Smaller font size for smaller screens */
  }

}
