.crew-card {
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  aspect-ratio: 1 / 1.75;
  background-color: #003c55;
  border: 10px solid #00aff0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  perspective: 600px;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out; // Unified transition
  transform: scale(0.75); // Default smaller scale for non-active cards

  &-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.5s; // Ensure this does not conflict with the hover effect
  }

  &-front, &-back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; // Adjust alignment
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: clamp(24px, 1.5vw, 32px); // Responsive font size for the text
    color: white;
  }

  &-back {
    backface-visibility: hidden; // Initially hidden
    transform: rotateY(180deg);
  }

  .crew-image-container {
    width: 100%; // Makes the image responsive within its container
    max-width: 400px; // Maximum size on larger screens
    height: auto; // Maintain aspect ratio
    border-radius: 50%;
    margin-bottom: 20px;
    transition: width 0.3s ease; // Smooth transition for resizing


  }

  .crew-name { // Combine similar styles
    margin-top: 10px;
    font-size: clamp(32px, 3.5vw, 64px); // Responsive font size for the name
    font-weight: bold;
    margin-bottom: 10px;
  }

  .crew-position, .crew-rank {
    font-size: clamp(24px, 2.5vw, 40px); // Responsive font size for position and rank
    font-style: italic;
    margin-bottom: 10px;
  }

  &-bio {
    margin-bottom: 20px;
  }

  &:hover {
    transform: rotateY(180deg); // This may conflict with slick's transform
  }

  &:hover .crew-card-front {
    display: none; // Hide front on hover
  }

  &:hover .crew-card-back {
    backface-visibility: visible; // Show back on hover
    display: flex;
  }

  .crew-image {
    width: 100%; // Responsive width
    height: auto;
    aspect-ratio: 1 / 1; // Enforce square aspect ratio
    object-fit: cover; // Ensure the image covers the area without distorting aspect ratio
    border-radius: 50%; // Makes the image round
    margin-bottom: 20px;
    transition: width 0.3s ease; // Smooth transition for resizing
  
 
  }
}
