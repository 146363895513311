.home {
  .hero {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/verse-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;

    .content-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      padding: 0 20px;

      @media (max-width: 992px) {
        flex-direction: column;
        text-align: center;
      }

      .content {
        max-width: 800px;
        padding: 20px;
        color: white;

        @media (max-width: 992px) {
          order: 2;
        }
      }

      .logo {
        max-width: 800px;
        max-height: 822px;

        img {
          width: 80%;
          height: auto;
          max-width: 800px;

          @media (max-width: 992px) {
            max-width: 300px;
          }
        }
      }
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    a {
      margin: 0 10px;
      color: white;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: #00aff0;
      }
    }
  }

  h1, h2 {
    font-size: clamp(1.25rem, 5vw, 2.5rem);
  }

  iframe {
    width: 100%;
    max-width: 800px;
    height: 352px;
    aspect-ratio: 16 / 9;
  }
}
