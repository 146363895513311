.crew-roster {
  // margin: auto;
  // padding: 20px;
  background-image: url('../../../public/nebulablue.jpg');
  background-size: cover; // Ensures the background covers the entire container
  background-position: center; // Centers the background image
  background-repeat: no-repeat; // Prevents the background image from repeating
  min-height: 100vh; // Minimum height of 100% of the viewport height
  width: 100vw; // Width of 100% of the viewport width
  padding: 20px;
  justify-content: center;
  align-items: center;

  h1 {
    padding-top: 50px;
    font-size: 40px;
    font-weight: bold;
    color: #fff200;
    text-align: center;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 -15px; // Adjust as needed
  }

  .col-lg-8,
  .col-md-12,
  .col-sm-12 {
    padding: 0 15px; // Adjust as needed
  }
}

.crew-title {
  padding-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  color: #fff200;
  font-size: 40px;
}

