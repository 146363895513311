.App {
  text-align: center;
  align-items: center;
  color: white;
  background-color: black;
  padding: 100px 0; // Consistent padding around the application

  main {
    padding: 0 20px; // Ensures padding inside the main container for better edge spacing

    > div {
      margin-bottom: 30px; // Spacing between sections
    }
  }

}

@media (max-width: 992px) {
  .App {
    .main {
      justify-content: center;
      padding: 120px 10px; // Reduced padding for smaller screens

      > div {
        margin-bottom: 50px; // Smaller margin between sections for tighter mobile layout
      }
    }
    // .home {
    //   display: none;
    // }
  }
}

