.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: black;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  width: 100%;

  .navbar {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #000 !important;
  }


  .navbar-brand {
    padding-left: 20px;
    cursor: pointer;
    img {
      height: 60px; // Make sure your logo fits well
    }
  }



  .navbar-collapse {
    display: flex;
    flex-grow: 1;
    justify-content: center; // Centers the navigation links
  }


  .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-right: 20px;

    .nav-item .nav-link {
      color: #fff200;
      margin: 0 15px;
      font-size: 24px;
      cursor: pointer;

      &:hover {
        text-shadow: 1px 1px 0 #00aff0;;
      }
    }
  }

  .btn-primary {
    margin-left: auto;
    padding-right: 20px;
    background-color: #ed1c24;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 24px;
    font-weight: bold;

    &:hover {
      background-color: darken(#ed1c24, 10%);
    }
  }
}

@media (max-width: 992px) {
  .navbar-collapse, .navbar-nav, .btn-primary, .nav-item, .nav-link {
    display: none;
  }

  .navbar-brand {
    padding-left: 0;
    justify-content: center;
    align-items: center;
  }

}
